.Mapas__dist-footer {
  color: #ffffff;
  font-weight: bolder;
  text-align: center;
}
/* --------------- M E D I A     Q U E R I E S --------------- */

/* general */
@media only screen and (min-width: 1024px) {
  .Mapas__dist-footer {
    padding: 8px;
    font-size: 25px !important;
  }
}

/* tablets */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .Mapas__dist-footer {
    padding: 6px;
    font-size: 15px !important;
  }
}

/* mobil */
@media only screen and (max-width: 767px) {
  .Mapas__dist-footer {
    padding: 2px;
    font-size: 10px !important;
  }
}
