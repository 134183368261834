.Home__pages {
  display: grid;
  justify-content: center;
  align-items: center;
}

.Home__pages div:hover {
  transform: scale(1.2);
  transition: 0.5s;
}

.Home__informes div {
  border-radius: 10px;
}

.Home__informes div:hover {
  transform: scale(1.1);
  transition: 0.5s;
  box-shadow: 3px 3px 3px #3b3b3b;
}

/* --------------- M E D I A     Q U E R I E S --------------- */

/* general */
@media only screen and (min-width: 1024px) {
  .Home__AR-A {
    display: grid;
    grid-template-columns: 48% 48%;
    column-gap: 4%;
  }

  .Home__informes {
    display: grid;
    grid-template-columns: auto auto auto auto;
    column-gap: 30px;
    row-gap: 60px;
  }

  .Home__boxesTriple {
    display: grid;
    grid-template-columns: 32% 32% 32%;
    column-gap: 2%;
  }

  .Home__boxesTriple>div:nth-child(1) div,
  .Home__boxesTriple>div:nth-child(3) div {
    overflow: auto;
    max-height: 280px;
  }

  .Home__EspITEVidPod {
    display: flex;
    justify-content: center;
    align-items: initial;
    gap: 20px;
    width: 100%;
  }

  .Home__EspITEVidPod .item1 {
    width: 65%;
  }

  .Home__EspITEVidPod .item2 {
    width: 35%;
  }

  .Home__pages {
    grid-template-columns: auto auto auto auto auto;
    column-gap: 60px;
    padding: 0 8%;
    margin-bottom: 10px;
  }
}

/* tablets */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .Home__informes {
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 80px;
    row-gap: 30px;
  }

  .Home__AR-A {
    display: grid;
    grid-template-columns: 48% 48%;
    column-gap: 4%;
  }

  .Home__pages {
    grid-template-columns: auto auto auto;
    column-gap: 60px;
    row-gap: 10px;
    padding: 0 8%;
    margin-bottom: 10px;
  }
}

/* mobil */
@media only screen and (max-width: 767px) {
  .Home__boxesTriple .Home__video-embed,
  .Home__AR-A .Home__video-embed,
  .Home__EspITEVidPod .item2

  /*.Home__video-embed*/
    {
    width: 80% !important;
    margin: auto;
  }

  .Home__informes {
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 20px;
    row-gap: 20px;
  }

  .Home__pages {
    grid-template-columns: auto auto;
    column-gap: 40px;
    row-gap: 5px;
    padding: 0 12%;
    margin-bottom: 20px;
  }
}