.Enlances__title {
  text-align: center;
  border-top: 3px solid #aa0787;
  border-bottom: 3px solid #aa0787;
  color: #aa0787;
  font-size: 20px;
  font-weight: bolder;
  padding: 4px 0;
  margin: 40px 0 0 0;
}

/* .hover:hover {
  transform: scale(102%);
  transition: cubic-bezier(0.77, 0, 0.175, 1);
} */

.shadow_card {
  box-shadow: 10px 10px 10px rgb(143, 143, 143) !important;
}

.shadow_img {
  box-shadow: -10px 10px 8px rgb(46, 46, 46) !important;
}

.grid-4 {
  display: grid;
}

.grid-4-item {
  height: 100% !important;
  box-shadow: 10px 10px 10px rgb(143, 143, 143) !important;
}

.grid-4-item div:nth-child(1) {
  display: flex;
  justify-content: center;
  align-items: center;
}

.grid-4-item div:nth-child(2) {
  text-align: center;
  -webkit-text-stroke: 2px #aa0787;
  letter-spacing: 2px;
  color: #aa0787;
  padding: 0px 5px;
}


/* --------------- M E D I A     Q U E R I E S --------------- */

/* general */
@media only screen and (min-width: 1024px) {
  .grid-4 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 20px;
  }

  .grid-4-item div:nth-child(1) {
    height: 75% !important;
    padding: 30px 0px !important;
  }

  .grid-4-item div:nth-child(2) {
    height: 25% !important;
    font-size: 20px !important;
  }
}

/* tablets */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .grid-4 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 15px;
  }

  .grid-4-item div:nth-child(1) {
    height: 75% !important;
    padding: 30px 0px !important;
  }

  .grid-4-item div:nth-child(2) {
    height: 25% !important;
    font-size: 15px !important;
  }
}

/* mobil */
@media only screen and (max-width: 767px) {
  .grid-4 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 20px;
  }

  .grid-4-item div:nth-child(1) {
    height: 60% !important;
    padding: 0px;
  }

  .grid-4-item div:nth-child(1) img {
    margin: 10px !important;
  }

  .grid-4-item div:nth-child(2) {
    height: 40% !important;
    font-size: 12px !important;

  }
}