/* --------------------------------------------- */
.Preguntas__list {
  font-weight: bolder;
  font-size: 20px;
  color: #aa0787;
  text-align: justify;
  margin-bottom: 10px;
}

.Transparencia__header a:hover {
  transition: 0.5s;
  transform: scale(1.05);
}

/* --------------- M E D I A     Q U E R I E S --------------- */

/* general */
@media only screen and (min-width: 1024px) {
  .Transparencia__header {
    display: flex;
    justify-content: space-around;
    padding: 0 2%;
  }

  .Transparencia__header a {
    width: 40%;
    border-radius: 20px;
    box-shadow: 5px 5px 3px #686868;
  }
}

/* tablets */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .Transparencia__header {
    display: flex;
    justify-content: space-around;
    padding: 0 2%;
  }

  .Transparencia__header a {
    width: 40%;
    border-radius: 20px;
    box-shadow: 5px 5px 3px #686868;
    margin-bottom: 20px;
  }
}

/* mobil */
@media only screen and (max-width: 767px) {
  .Transparencia__header {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
}
