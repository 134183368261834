.Videoteca {
    width: 100%;
    padding: 1em 0;
    display: flex;
    overflow: hidden;
}

.Videoteca-content {
    display: flex;
}

.Videoteca-content:hover {
    animation-play-state: paused;
}

.Videoteca-tag:hover {
    background: #adadad;
    transform: scale(1.1);
    cursor: pointer;
}

/* --------------- M E D I A     Q U E R I E S --------------- */

/* general */
@media only screen and (min-width: 1024px) {
    .Videoteca-content {
        animation: videotecaAnimation 90s linear 5s infinite;
    }

    .Videoteca-tag {
        width: 400px;
        margin: 0 0.5em;
        padding: 0.5em;
        background: rgba(255, 255, 255, 0.1);
        transition: all 0.2s ease;
    }
}

/* tablets */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
    .Videoteca-content {
        animation: videotecaAnimation 120s linear 5s infinite;
    }

    .Videoteca-tag {
        width: 300px;
        margin: 0 0.5em;
        padding: 0.5em;
        background: rgba(255, 255, 255, 0.1);
        transition: all 0.2s ease;
    }
}

/* mobil */
@media only screen and (max-width: 767px) {
    .Videoteca-content {
        animation: videotecaAnimation 150s linear 5s infinite;
    }

    .Videoteca-tag {
        width: 300px;
        margin: 0 0.5em;
        padding: 0.5em;
        background: rgba(255, 255, 255, 0.1);
        transition: all 0.2s ease;
    }
}

/* --------------- K E Y F R A M E S --------------- */

@keyframes videotecaAnimation {
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(-100%);
    }
}