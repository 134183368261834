.NavbarEnlaces__item:hover {
  transform: scale(1.05);
  transition: 0.5s;
  cursor: pointer;
}

/* --------------- M E D I A     Q U E R I E S --------------- */

/* general */
@media only screen and (min-width: 1024px) {
  .NavbarEnlaces {
    display: grid;
    grid-template-columns: auto auto auto auto auto auto auto auto;
    gap: 25px;
  }
}

/* tablets */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .NavbarEnlaces {
    display: grid;
    grid-template-columns: auto auto auto auto auto auto auto auto;
    gap: 5px;
  }
}

/* mobil */
@media only screen and (max-width: 767px) {
  .NavbarEnlaces {
    display: grid;
    grid-template-columns: auto auto auto auto;
    gap: 20px;
  }
}
