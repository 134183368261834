.Footer {
    background-color: #ba1678;
    color: #ffffff;
    font-family: 'Segoe UI Light';
    font-size: 1rem;
    width: 100%;
    padding: 8px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 20px;
    position: absolute;
    bottom: 0;
    left: 0;
}

/* --------------- M E D I A     Q U E R I E S --------------- */

/* tablets */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
    .Footer {
        display: block;
        text-align: center;
    }
}

/* mobil */
@media only screen and (max-width: 767px) {
    .Footer {
        text-align: center;
        font-size: .8rem;
        display: block;
    }

    .Footer div {
        margin: 5px 0;
    }
}